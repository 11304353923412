<template>
  <section class="section" id="consulta-caixa">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column">
          <h3 class="title is-3 has-text-primary">
            Caixa
          </h3>
        </div>
        <div class="column is-1" style="min-width: fit-content">
          <router-link to="/admin/adicionar-caixa" class="button is-primary is-fullwidth">
            <b-icon icon="cash-plus" class="mr-1" />
            Adicionar
          </router-link>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-one-third">
          <b-field label="Conta Bancária">
            <b-select v-model="filtro.contaId" placeholder="Selecione" expanded>
              <option v-for="conta in contaBancaria" :key="conta.id" :value="conta.id">
                {{ conta.nome }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-two-fifths">
          <b-field label="Periodo de Lançamentos">
            <b-input type="date" v-model="filtro.dataI" />
            <b-input type="date" v-model="filtro.dataF" />
          </b-field>
        </div>
        <div class="column" style="display: flex; align-self: center; justify-content: end;">
          <b-field label="Saldo Atual">
            <p>R$ {{ getSaldo() }}</p>
          </b-field>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-full">
          <b-button type="is-secondary" expanded @click="getLancamentos">
            Buscar
          </b-button>
        </div>
      </div>
      <section v-if="lancamentos.length === 0 && !isLoading" class="section has-background-light has-text-centered" style="border-radius: 10px">
        Nenhum resultado encontrado para a sua busca.
      </section>
      <b-table v-else :data="lancamentos" :loading="isLoading" hoverable mobile-cards>
        <b-table-column field="data" label="Data" centered v-slot="props">
          {{ props.row.data | maskDate }}
        </b-table-column>

        <b-table-column field="usuario" label="Usuario" centered v-slot="props">
          {{ props.row.usuario.nome }}
        </b-table-column>

        <b-table-column field="centroCusto" label="Centro de Custo" centered v-slot="props">
          {{ props.row.centroCusto.nome }}
        </b-table-column>

        <b-table-column field="tipo" label="Tipo" centered v-slot="props">
          {{ props.row.tipo }}
        </b-table-column>

        <b-table-column field="descricao" label="Descrição" centered v-slot="props">
          {{ props.row.descricao }}
        </b-table-column>

        <b-table-column field="valor" label="Valor" centered v-slot="props">
          {{ props.row.valor }}
        </b-table-column>

        <b-table-column field="saldo" label="Saldo" v-slot="props">
          {{ props.row.saldo }}
        </b-table-column>
      </b-table>
    </div>
  </section>
</template>

<script>
import { formatFixedDateInitial, formatFixedDateFinal } from '@/helpers/helpers';
import financeiroContaService from '@/services/financeiroContaService';
import financeiroLancamentoService from '@/services/financeiroLancamentoService';

export default {
  name: 'Caixa',
  data() {
    return {
      contaBancaria: [],
      lancamentos: [],
      isLoading: false,
      filtro: {
        dataI: formatFixedDateInitial(new Date()),
        dataF: formatFixedDateFinal(new Date()),
      },
    };
  },
  created() {
    this.getContaBanco().then(() => {
      this.getLancamentos();
    });
  },
  methods: {
    async getContaBanco() {
      return financeiroContaService.get().then(({ data }) => {
        this.contaBancaria = data;
        const [conta] = data;
        if (conta) {
          this.filtro.contaId = conta.id;
        }
      });
    },

    async getLancamentos() {
      this.isLoading = true;
      await financeiroLancamentoService
        .getByRangeData(this.filtro)
        .then(({ data }) => {
          this.lancamentos = data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getSaldo() {
      if (this.filtro.contaId) {
        const dadosBancario = this.contaBancaria.find(c => c.id === this.filtro.contaId);
        return dadosBancario.saldo;
      }
      return '00.00';
    },
  },
};
</script>

<style lang="scss">
.btn-search {
  display: flex;
  align-items: flex-end;
  margin-left: 50px;
}
</style>
